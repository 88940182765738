<template>
    <div>
        <router-link class="review-organizer card d-flex flex-column align-items-center text-dark text-decoration-none rounded-xl shadow-sm"
                     v-bind:to="`/organizers/${ id }`">
            <div class="card-body d-flex align-items-center px-2 py-1">
                <h4 class="text-center font-weight-bold text-nowrap mb-0">
                    <b-avatar class="shadow-sm border-0 mr-2"
                              size="md">
                        <b-spinner v-if="isLoading"></b-spinner>
                        <img class="w-100 h-100"
                             style="object-fit: cover"
                             v-else-if="avatar"
                             v-bind:alt="`${ name } (${ phone })`"
                             v-bind:src="avatar">
                        <span v-else>
                        <font-awesome-icon v-bind:icon="['fad', 'user-crown']"></font-awesome-icon>
                    </span>
                    </b-avatar>
                </h4>
                <div class="text-left">
                    <p class="font-weight-bolder text-nowrap mb-0">
                        {{ name }}<br>
                        {{ phone }}
                    </p>
                </div>
            </div>
        </router-link>
        <p class="text-center text-nowrap mb-1">
            <small>Organizer👨‍💼</small>
        </p>
    </div>
</template>

<script>
import { apiUrl, apiVersion } from "../../../config";
import { BAvatar, BSpinner } from "bootstrap-vue";

export default {
    name: "ReviewOrganizer",
    components: {
        BAvatar, BSpinner,
    },
    props: {
        isLoading: {
            type: Boolean,
            default: false,
        },
        organizer: {
            type: Object,
        },
    },
    data () {
        return {

        };
    },
    computed: {
        currentRegion () {
            return this.$route.params?.region ?? "HKG";
        },
        id () {
            return this.organizer?._id ?? "";
        },
        avatar () {
            return this.organizer?.avatar ?
                `${ apiUrl }/${ apiVersion }/user/${ this.organizer._id }/avatar` :
                "";
        },
        name () {
            return this.organizer ? `${ this.organizer?.name ?? "" }` : "Loading...";
        },
        phone () {
            return this.organizer ? `+${ this.organizer?.countryCode ?? "" } ${ this.organizer?.phone ?? "" }` : "";
        },
    },
    methods: {

    },
}
</script>

<style lang="scss" scoped>
.review-organizer {
    transition: box-shadow 200ms ease-in-out;

    &:hover,
    &:focus {
        box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
    }
}
</style>
